import dynamic from 'next/dynamic';

export const dynamicErrorPageTemplates = {
  ecg: dynamic(
    () => import('./page-templates/EcgNotFoundPage/EcgNotFoundPage')
  ),
  eeg: dynamic(
    () => import('./page-templates/EegNotFoundPage/EegNotFoundPage')
  ),
  enercity: dynamic(() => import('./page-templates/NotFoundPage/NotFoundPage')),
  eng: dynamic(
    () => import('./page-templates/EngNotFoundPage/EngNotFoundPage')
  ),
  esol: dynamic(
    () => import('./page-templates/ESolNotFoundPage/ESolNotFoundPage')
  ),
  esg: dynamic(
    () => import('./page-templates/ESgNotFoundPage/ESgNotFoundPage')
  ),
  'mein-h-gas': dynamic(
    () => import('./page-templates/MeinHGasNotFoundPage/MeinHGasNotFoundPage')
  ),
  // plopfile generator import
} as const;
